import { Button, Col, Row, Modal, ModalBody, Label, Input, Form, FormGroup, CardBody, Card } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { create } from "../../api/apiBlog";
import { convertBase64 } from "../../common/constants/Services";
import Select from 'react-select';
import ImageDefault from "../../assets/images/featured-job/img-default.webp"
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";


const AddEdit = (props) => {
    let [isOpen, setIsOpen] = useState(false);
    let [onClose, setOnClose] = useState(() => { });
    let [onSave, setOnSave] = useState(() => { });
    let [editorContent, setEditorContent] = useState(EditorState.createEmpty());
    let [postTitle, setPostTitle] = useState("");
    let [postContent, setPostContent] = useState("");
    let [postCategory, setPostCategory] = useState(null);
    let [postId, setPostId] = useState(0);
    let [image, setImage] = useState(null);
    let [base64File, setBase64File] = useState(null);

    useEffect(() => {
        if (props) {
            if (props.isOpen) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
            if (props.onClose) {
                setOnClose(() => () => props.onClose());
            }
            if (props.onSave) {
                setOnSave(() => () => props.onSave());
            }
            if (props.currentPost) {
                setPostTitle(props.currentPost.title);
                const blocksFromHTML = htmlToDraft(props.currentPost.content);
                const content = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                setEditorContent(EditorState.createWithContent(content));
                setPostContent(props.currentPost.content);
                setPostId(props.currentPost.id);
                setImage(props.currentPost.image);
                setPostCategory(null);
                for (let item of props.categoryList) {
                    if (item.value === props.currentPost.categoryId) {
                        setPostCategory(item);
                        break;
                    }
                }
            } else {
                setPostTitle("");
                setPostContent("");
                setPostId(0);
                setEditorContent(EditorState.createEmpty());
                setImage(null);
                setBase64File(null);
                setPostCategory(0);
            }
        }
    }, [props]);

    const ChangeTitle = (e) => {
        setPostTitle(e.target.value);
    };

    const handleEditorChange = content => {
        setPostContent(content);
    };

    const handlePostContent = editorState => {
        let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setPostContent(content);
        setEditorContent(editorState);

    };

    const chooseCategory = (e) => {
        setPostCategory(e);
    }

    const handleUploadImage = async event => {
        const bannerLoaded = URL.createObjectURL(event.target.files[0]);
        const base64 = await convertBase64(event.target.files[0]);
        let base64Data = base64.split(',');
        setBase64File(base64Data[1]);
        setImage(bannerLoaded);
    }

    const Save = () => {
        let data = {
            id: postId,
            title: postTitle,
            content: postContent,
            base64File: base64File,
            image: image,
            categoryId: postCategory.value
        }
        create(data).then((res) => {
            if (res && res) {
                if (res.status) {
                    onSave();
                    onClose();
                } else {
                    alert("Lưu thất bại");
                }
            }
        });


    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} role="dialog" centered fullscreen
                backdrop="static"
                scrollable={true}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            {props.title ? props.title : ""}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => onClose()}
                        ></button>
                    </div>
                    <ModalBody>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <Row>
                                                <Col lg={12}>
                                                    <FormGroup className="mb-4">
                                                        <Label for="basicpill-firstname-input">Ảnh </Label>
                                                        <div className="mb-4 profile-user">
                                                            <img
                                                                src={image ? image : ImageDefault}
                                                                className="rounded-3 img-thumbnail"
                                                                id="profile-img"
                                                                alt=""
                                                                style={{ maxHeight: "200px" }}
                                                            />
                                                            <div className="p-0 rounded-3 profile-photo-edit">
                                                                <Input
                                                                    id="profile-img-file-input"
                                                                    type="file"
                                                                    className="profile-img-file-input"
                                                                    onChange={handleUploadImage}
                                                                />
                                                                <Label
                                                                    htmlFor="profile-img-file-input"
                                                                    className="profile-photo-edit avatar-xs"
                                                                >
                                                                    <i className="uil uil-edit"></i>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <FormGroup className="mb-4">
                                                        <Label for="basicpill-category-input">Danh mục</Label>
                                                        <Select
                                                            isMulti={false}
                                                            options={props.categoryList}
                                                            value={postCategory}
                                                            onChange={chooseCategory}
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    backgroundColor: "#ffffff",
                                                                    borderColor: '#eff0f2',
                                                                    height: '42.6px',
                                                                    fontWeight: 500,
                                                                    paddingLeft: 40
                                                                }),
                                                            }}
                                                            className="react-select-container"
                                                            classNamePrefix="react-select"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <FormGroup className="mb-4">
                                                        <Label for="basicpill-title-input">Tiêu đề</Label>
                                                        <Input type="text" className="form-control" id="basicpill-title-input" defaultValue={postTitle} onChange={ChangeTitle} />
                                                    </FormGroup>
                                                    <FormGroup className="mb-4">
                                                        <Label for="basicpill-content-input">Nội dung</Label>
                                                        <SunEditor
                                                            setContents={postContent}
                                                            showToolbar={false}
                                                            onChange={handleEditorChange}
                                                            setDefaultStyle="height: auto"
                                                            setOptions={{
                                                                buttonList: [
                                                                    ["undo", "redo"],
                                                                    ["font", "fontSize", "formatBlock"],
                                                                    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                                                    ["removeFormat"],
                                                                    "/",
                                                                    ["fontColor", "hiliteColor"],
                                                                    ["outdent", "indent"],
                                                                    ["align", "horizontalRule", "list", "table"],
                                                                    ["link", "image", "video"],
                                                                    ["fullScreen", "showBlocks", "codeView"],
                                                                    ["preview", "print"],
                                                                ],
                                                                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                                                font: [
                                                                    "Arial",
                                                                    "Calibri",
                                                                    "Comic Sans",
                                                                    "Courier",
                                                                    "Garamond",
                                                                    "Georgia",
                                                                    "Impact",
                                                                    "Lucida Console",
                                                                    "Palatino Linotype",
                                                                    "Segoe UI",
                                                                    "Tahoma",
                                                                    "Times New Roman",
                                                                    "Trebuchet MS"
                                                                ]
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <Button type="button" color="secondary" className="me-1" onClick={() => onClose()} >Hủy</Button>
                                                    <Button type="button" color="primary" onClick={() => Save()} >Lưu</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                </div>
            </Modal>

        </React.Fragment>
    );
}

export default AddEdit;